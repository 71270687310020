<template>
  <div v-if="panel">
    <ul class="nav nav-tabs control-sidebar-tabs">
      <li
        :title="$t('properties')"
        data-testid="properties-tab"
        :class="{active: tab == 'synoptic-component'}"
        @click.stop.prevent="setTab('synoptic-component')"
      >
        <a href="javascript:void()" data-toggle="tab" aria-expanded="true">
          <template v-if="controlThumbnail">
            <!-- <i class="fa fa-cube" :title="controlThumbnail"></i> -->
            <img :src="controlThumbnail" class="control-icon" />
            <span>{{ $tc("control", 1) }}</span>
          </template>
          <template v-else>
            <i class="fa fa-panel"></i>
            <span>{{ $tc("panel", 1) }}</span>
          </template>
        </a>
      </li>
      <li
        :title="$t('controls')"
        data-testid="controls-tab"
        :class="{active: tab == 'synoptic-components'}"
        @click.stop.prevent="setTab('synoptic-components')"
      >
        <a href="javascript:void()" data-toggle="tab" aria-expanded="true">
          <i class="fa fa-cubes"></i> <span>{{ $tc("controls", 2) }}</span>
        </a>
      </li>
      <li
        :title="$t('events')"
        data-testid="events-tab"
        :class="{active: tab == 'synoptic-actions'}"
        @click.stop.prevent="setTab('synoptic-actions')"
        v-if="currentWidget"
      >
        <a href="javascript:void()" data-toggle="tab" aria-expanded="true">
          <i class="fa fa-flash"></i> <span>{{ $t("events") }}</span>
        </a>
      </li>
    </ul>
    <div class="tab-content">
      <div class="tab-pane" :class="{active: tab == 'synoptic-component'}">
        <PanelTypeTitle
          :title="$tc('synoptic.panels.synoptic', 1)"
          :buttonLabel="editButton.label"
          :buttonIcon="editButton.icon"
          @edit="editGeneralPanelProperties"
        >
          <template #title>
            <div>
              <span>
                {{ $tc("synoptic.panels.synoptic", 1) }}
              </span>
              <PanelPosition :panelName="panel.name" class="panel-at" />
            </div>
          </template>
        </PanelTypeTitle>
        <div>
          <div class="content-header">
            <div v-if="controlThumbnail">
              <ControlHeader />
            </div>
            <div v-else>
              <span class="fa fa-sliders"></span>
              <span style="margin-left: 5px"
                >{{ $t("specific_properties") }}
              </span>
            </div>
          </div>
          <component
            v-if="currentWidget"
            :is="currentWidget"
            :controls="selectedControls"
            @controlChanged="onControlChanged"
            data-testid="selected-control-widget"
          />
          <Canvas v-else data-testid="canvas" :controls="selectedControls" />
        </div>
      </div>
      <div class="tab-pane" :class="{active: tab == 'synoptic-components'}">
        <PanelTypeTitle
          title="synoptic.panels.synoptic"
          @edit="editGeneralPanelProperties"
          :buttonLabel="editButton.label"
          :buttonIcon="editButton.icon"
        />
        <TogglePanel
          title="available_controls"
          data-testid="toggle-panel"
          persistent="toggle_synoptic_available_controls"
        >
          <ControlGalleryPanel data-testid="gallery-panel" />
        </TogglePanel>
        <ControlSelectionPanel />
      </div>
      <div class="tab-pane" :class="{active: tab == 'synoptic-actions'}">
        <PanelTypeTitle
          title="synoptic.panels.synoptic"
          @edit="editGeneralPanelProperties"
          :buttonLabel="editButton.label"
          :buttonIcon="editButton.icon"
        />
        <div>
          <div class="content-header">
            <div v-if="controlThumbnail">
              <ControlHeader />
            </div>
            <div v-else>
              <span class="fa fa-sliders"></span>
              <span style="margin-left: 5px"
                >{{ $t("specific_properties") }}
              </span>
            </div>
          </div>
          <ControlEventEditor
            :control="control"
            @delEvent="onDelEvent($event)"
            @addEvent="onAddEvent($event)"
            style="margin: 10px -10px 0 -10px"
            ref="ControlEventEditor"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Events from "@/assets/dashboard/events.json";
import Controls from "@/assets/dashboard/controls.json";
import TogglePanel from "@/components/control-sidebar/toggle-panel.vue";
import ControlGalleryPanel from "@/components/synoptic/property-editor/control-gallery-panel.vue";
import ControlSelectionPanel from "@/components/synoptic/property-editor/control-selection-panel.vue";
import ControlEventEditor from "@/components/editor/control-event-editor.vue";
import PanelTypeTitle from "@/components/control-sidebar/property-editors/panel-type-title.vue";
import ControlHeader from "@/components/editor/control-header.vue";
import PanelPosition from "@/components/control-sidebar/property-editors/panel-position.vue";
export default {
  name: "SynopticPropertyEditor",
  components: {
    TogglePanel,
    ControlGalleryPanel,
    ControlSelectionPanel,
    ControlEventEditor,
    PanelTypeTitle,
    ControlHeader,
    PanelPosition,
    Canvas: () => import("./controls/canvas"),
    IconizedDisplayValue: () => import("./controls/iconized-display-value"),
    StatusIcon: () => import("./controls/status-icon"),
    StatusLabel: () => import("./controls/status-label"),
    StaticLabel: () => import("./controls/static-label"),
    FormattedDisplayValue: () => import("./controls/formatted-display-value"),
    SynopticImage: () => import("./controls/synoptic-image"), // vue does not allow the usage of "Image" as component
    DataValueInput: () => import("./controls/data-value-input"),
    DataValueSlider: () => import("./controls/data-value-slider"),
    DataValueSelect: () => import("./controls/data-value-select"),
    DataValueToggle: () => import("./controls/data-value-toggle"),
    DataValueCheckbox: () => import("./controls/data-value-checkbox"),
    SynopticButton: () => import("./controls/synoptic-button"), // vue does not allow the usage of "Button" as component
    ProgressBar: () => import("./controls/progress-bar"),
    Gauge: () => import("./controls/gauge.vue"),
    SimpleTable: () => import("./controls/simple-table"),
    SynopticChart: () => import("./controls/chart")
  },
  data() {
    return {
      tab: "synoptic-components"
    };
  },
  computed: {
    currentWidget() {
      let name = this.selectedControls.length
        ? this.selectedControls[0].synopticComponent.componentName
        : "";
      return name
        ? name in this.$options.components
          ? name
          : name.replace("Synoptic", "") in this.$options.components
          ? name.replace("Synoptic", "")
          : null
        : null;
    },
    originalControl() {
      if (this?.selectedControls?.length) {
        return Controls.find(
          ({name}) =>
            name == this.selectedControls[0].synopticComponent.componentName
        );
      }
      return null;
    },
    controlText() {
      let ctrl = this.originalControl;
      return (ctrl && ctrl.template.title) || "";
    },
    controlThumbnail() {
      return this?.originalControl?.thumbnail || "";
    },
    editButton() {
      return {
        icon: this?.originalControl ? "fa fa-sliders" : "fa fa-gear",
        label: this?.originalControl ? "specific_properties" : "general"
      };
    },
    panel() {
      return this.$store.getters["dashboard/currentDraftPanel"] || null;
    },
    selectedControls() {
      return this.$store.getters["synoptic/selectedControls"];
    },
    control() {
      // returns just the first control
      return (this.selectedControls ?? []).length
        ? this.selectedControls[0]
        : null;
    }
  },
  methods: {
    editGeneralPanelProperties() {
      let panel = this.panel || null;
      if (!panel) return;
      let showContentProperties = this?.originalControl ? true : false;
      this.$store.dispatch("synoptic/clearSelectedControls");
      this.$nextTick(() => {
        this.$root.$emit("dashboard:editor", {
          action: "editPanel",
          data: {
            panelName: panel.name,
            showContentProperties: showContentProperties
          }
        });
      });
    },
    setTab(value) {
      this.tab = value;
    },
    onAddEvent($e) {
      if (!this.control || (typeof $e !== "string" && typeof $e !== "object"))
        return;
      let control = JSON.parse(JSON.stringify(this.control));
      control.synopticComponent.on = control.synopticComponent.on || {};
      if (typeof $e === "string") {
        let evt = JSON.parse(JSON.stringify(Events[$e]));
        control.synopticComponent.on[$e] = evt;
      } else if (typeof $e === "object") {
        for (var p in $e || {}) {
          if (!control.synopticComponent.on[p]) {
            control.synopticComponent.on[p] = JSON.parse(JSON.stringify($e[p]));
          }
        }
        this.$nextTick(() => {
          this.$refs.ControlEventEditor &&
            this.$refs.ControlEventEditor.collapseAll();
        });
      }
      this.$store.commit("synoptic/UPDATE_CONTROL_BY_ID", {
        id: control.id,
        control: control
      });
    },
    onDelEvent(name) {
      if (!this.control) return;
      let control = JSON.parse(JSON.stringify(this.control));
      control.synopticComponent.on = control.synopticComponent.on || {};
      this.$delete(control.synopticComponent.on, name);
      this.$store.commit("synoptic/UPDATE_CONTROL_BY_ID", {
        id: control.id,
        control: control
      });
    },
    onControlChanged(control) {
      if (control.synopticComponent.componentName == "SynopticImage") {
        this.panel.options.canvas.src = control.synopticComponent.src;
      }
    }
  },
  mounted() {
    this.$store.dispatch("synoptic/clearSelectedControls");
    this.$root.$on("synoptic:tab", this.setTab.bind(this));
  },
  beforeDestroy() {
    this.$root.$off("synoptic:tab", this.setTab.bind(this));
  }
};
</script>

<style scoped>
.control-icon {
  display: inline-block;
  width: 20px;
  filter: grayscale(1);
}

.nav {
  display: flex;
}

.nav-tabs {
  min-height: 40px;
}

.nav li {
  flex: 1;
}

.nav li a {
  display: flex;
  justify-content: center;
  width: 100%;
  opacity: 0.6;
}

.nav li a:hover {
  opacity: 1;
}

.nav li.active a {
  opacity: 1;
}
.nav > li > a {
  color: inherit;
}
.nav > li > a > span {
  margin: -2px 0 0 5px;
}
.property-panel-title {
  padding: 0 0 2px 0;
  line-height: 1em;
  text-align: center;
  color: gray;
  border-bottom: 1px solid lightgray;
}

.icon-container {
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-right: 4px;
}

.text-container {
  display: inline-block;
  vertical-align: middle;
  margin-top: 0px;
}

.panel-at {
  font-weight: normal;
  color: #929292;
  margin-right: 6px;
  font-size: 80%;
  vertical-align: super;
}
.content-header {
  font-size: 12pt;
  width: calc(100% + 60px);
  line-height: 1;
  font-weight: 500;
  color: inherit;
  margin: 0px 0px 5px -35px;
  position: relative;
  padding: 5px 10px;
  /* border-bottom: 1px solid #ddd; */
}
</style>
