<template>
  <section class="main" v-if="chartSeriesConfig && chartOptions">
    <!-- chart header -->
    <TogglePanel
      :title="$tc('rich_text.heading')"
      :icon="{
        collapse: 'fa-caret-square-o-up',
        expand: 'fa-caret-square-o-down'
      }"
      persistent="toogle_chart_panel_header"
    >
      <div class="inner-panel">
        <div class="form-group form-group-sm">
          <label class="label-inline" for="title">{{ $t("title") }}</label>
          <input
            :placeholder="`${(panel && panel.title) || $t('text')}`"
            class="form-control"
            v-model="titleText"
          />
        </div>
        <InlineDimensionForm
          v-if="target"
          title="padding"
          :lockbles="[0, 1, 2, 3]"
          :labels="['top', 'right', 'left', 'bottom']"
          :disabled="titlePaddingDisabled"
          v-model="titlePadding"
        />
        <ControlStyleProperties v-if="panel" v-model="titleStyle">
          <template #padding>
            <InlineDimensionForm
              title="padding"
              :lockbles="[0, 1, 2, 3]"
              :labels="['top', 'right', 'left', 'bottom']"
              :disabled="titlePaddingDisabled"
              v-model="titlePadding"
            />
          </template>
        </ControlStyleProperties>
      </div>
    </TogglePanel>

    <!-- data series -->
    <ChartSeriesForm
      v-model="chartSeriesConfig"
      :changeType="
        target || (panelOptions && panelOptions.realtime) ? false : true
      "
      :showApplyExpresson="!target"
      :showInterpolation="!target"
      :checkable="checkable"
      :dataListParser="dataListParser"
    >
      <template #before_axis>
        <!-- chart area (general)-->
        <TogglePanel
          :title="$tc('general')"
          :icon="{
            collapse: 'fa-caret-square-o-up',
            expand: 'fa-caret-square-o-down'
          }"
          persistent="toogle_chart_panel_general"
        >
          <div class="inner-panel" style="margin-top: -5px">
            <ChartGeneralForm v-model="chartOptions" />
            <TogglePanel
              :title="`${$t('realtime')}`"
              v-if="!panel || !namedQuery"
            >
              <div class="checkbox" v-if="panelOptions">
                <label>
                  <input type="checkbox" v-model="panelOptions.realtime" />
                  {{ $t("realtime_update") }}
                  <Tooltip :title="$t('hints.realtime_chart')" />
                </label>
              </div>
              <div
                v-if="target || (panelOptions && panelOptions.realtime)"
                class="inner-box"
              >
                <div class="form-group form-group-sm">
                  <label
                    >{{ $t("refresh_interval") }}
                    <Tooltip :title="$t('refresh_interval_desc')"
                  /></label>
                  <TimeInterval
                    v-model="chartOptions.refreshInterval"
                    options="s,m,h"
                    style="width: 100%"
                  />
                </div>
                <div class="form-group form-group-sm">
                  <label
                    >{{ $t("time_window") }}
                    <Tooltip :title="$t('time_window_desc')"
                  /></label>
                  <TimeInterval
                    v-model="chartOptions.timeWindow"
                    options="s,m,h"
                    style="width: 100%"
                  />
                </div>
                <div class="checkbox">
                  <label for="trailing-value">
                    <input
                      id="trailing-value"
                      v-model="chartOptions.trailingValue"
                      type="checkbox"/>{{ $t("trailing_value") }}
                    <Tooltip :title="$t('trailing_value_desc')"
                  /></label>
                </div>
              </div>
            </TogglePanel>
            <!-- disconnection form -->
            <DisconnectionChartForm
              v-model="disconnectionForm"
              v-if="
                !namedQuery &&
                  panel &&
                  panel.template == 'EquipmentHistoryChartPanel'
              "
            />
            <!-- disconnection form -->
          </div>
        </TogglePanel>
      </template>
      <template #after_axis> </template>
    </ChartSeriesForm>
    <template v-if="nDataList > 0">
      <!-- legends -->
      <TogglePanel
        :title="$tc('rich_text.legend', 1)"
        :icon="{
          collapse: 'fa-caret-square-o-up',
          expand: 'fa-caret-square-o-down'
        }"
        persistent="toogle_chart_panel_legend"
      >
        <div class="content-padding">
          <div class="row" style="">
            <div class="col-xs-8">
              <div>
                <label
                  style="white-space: nowrap"
                  class="checkbox-inline checkbox-inline-bottom-margin no-select"
                >
                  <input type="checkbox" v-model="legendShow" />
                  {{ $t("show_at_position") }}
                </label>
              </div>
              <div v-if="legendShow">
                <label
                  style="white-space: nowrap"
                  class="checkbox-inline checkbox-inline-bottom-margin no-select"
                >
                  <input type="checkbox" v-model="legendDynamicColor" />
                  {{ $tc("dynamic_color", 1) }}
                  <Tooltip :title="$t('hints.dynamic_color')" />
                </label>
              </div>
            </div>
            <div
              class="col-xs-4 text-center"
              style="padding-right: 60px"
              :style="{opacity: legendShow ? 1 : 0.5}"
            >
              <AlignSelector v-model="legendPosition" style="zoom: 0.8" />
            </div>
          </div>
          <InlineDimensionForm
            v-if="legendShow"
            title="padding"
            :lockbles="[0, 1, 2, 3]"
            :labels="['top', 'right', 'left', 'bottom']"
            v-model="legendPadding"
            :disabled="legendPaddingDisabled"
          />
        </div>
      </TogglePanel>
      <!-- tooltip -->
      <ChartTooltipForm
        v-model="chartTooltipConfig"
        v-if="chartTooltipConfig"
      />
    </template>
  </section>
</template>

<script>
import {debounce} from "lodash";
import TogglePanel from "@/components/control-sidebar/toggle-panel.vue";
import ControlStyleProperties from "@/components/synoptic/property-editor/controls/control-style-properties.vue";
import AlignSelector from "@/components/widgets/align-selector.vue";
import TimeInterval from "@/components/editor/time-interval.vue";
import InlineDimensionForm from "@/components/synoptic/property-editor/controls/inline-dimension-form";
import Tooltip from "@/components/tooltip.vue";
import Chart from "@/assets/dashboard/chart.json";
import ChartSeriesForm from "@/components/control-sidebar/property-editors/chart-series-form.vue";
import ChartGeneralForm from "@/components/control-sidebar/property-editors/chart-general-form.vue";
import DisconnectionChartForm from "@/components/control-sidebar/property-editors/disconnection-chart-form.vue";
import ChartTooltipForm from "@/components/control-sidebar/property-editors/chart-tooltip-form.vue";

const defSerie = (type) => {
  return JSON.parse(JSON.stringify(Chart.seriesOptions[type || "line"]));
};

const titleStyleProperty = {
  set(vlr) {
    if (this?.chartOptions?.title) {
      let title = {
        left: vlr["text-align"],
        padding:
          vlr.padding instanceof Array
            ? vlr.padding
            : this.chartOptions.title.padding,
        backgroundColor: vlr["background-color"],
        textAlign: vlr["text-align"],
        textStyle: {
          color: vlr.color,
          fontFamily: vlr["font-family"],
          fontSize: parseInt(vlr["font-size"]),
          fontStyle: vlr["font-style"],
          fontWeight: vlr["font-weight"]
        }
      };

      let chartOptions = {...this.chartOptions};
      chartOptions.title = Object.assign({...this.chartOptions.title}, title);
      this.chartOptions = chartOptions;
    }
  },
  get() {
    let style = {
      color: this.chartOptions.title.textStyle.color,
      padding: this.chartOptions.title.padding,
      "background-color":
        this.chartOptions.title.backgroundColor || "transparent",
      "font-family": this.chartOptions.title.textStyle.fontFamily,
      "font-size":
        (this.chartOptions.title.textStyle.fontSize + "").replace(/\D/g, "") +
        "pt",
      "font-style": this.chartOptions.title.textStyle.fontStyle,
      "font-weight": this.chartOptions.title.textStyle.fontWeight,
      "text-align":
        this.chartOptions.title.textAlign ||
        this.chartOptions.title.left ||
        "center"
    };
    return style;
  }
};

//
// it returns input dimension controls that must be disabled according to the position
// top,right,left,down
const disabledDimensions = (position) => {
  let ref = {
    "top-left": [false, true, false, true],
    "top-center": [false, true, true, true],
    "top-right": [false, false, true, true],
    "middle-left": [true, true, false, true],
    "middle-center": [true, true, true, true],
    "middle-right": [true, false, true, true],
    "bottom-left": [true, true, false, false],
    "bottom-center": [true, true, true, false],
    "bottom-right": [true, false, true, false],
    left: [false, true, false, true],
    right: [false, false, true, true],
    center: [false, true, true, true]
  };
  return ref[position] || [false, false, false, false];
};

export {defSerie, titleStyleProperty};

export default {
  name: "DetailFormChart",
  components: {
    TogglePanel,
    ControlStyleProperties,
    AlignSelector,
    TimeInterval,
    InlineDimensionForm,
    Tooltip,
    ChartSeriesForm,
    ChartGeneralForm,
    DisconnectionChartForm,
    ChartTooltipForm
  },
  props: {
    value: {
      type: Object,
      default: null
    },
    checkable: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      info: null,
      curDataId: null,
      dataSelector: true,
      target: null
    };
  },
  computed: {
    draft() {
      return this.$store.getters["dashboard/draft"] || null;
    },
    panel() {
      let currentDraftPanel =
        this.$store.getters["dashboard/currentDraftPanel"] || null;
      if (currentDraftPanel.template == "EquipmentHistoryChartPanel") {
        return currentDraftPanel;
      } else {
        return (
          (this?.info?.panelName &&
            (this?.draft?.template?.panels || []).find(
              (i) => i.name == this.info.panelName
            )) ||
          null
        );
      }
      // if (!this?.info?.panelName) return null;
      // return (
      //   (this.draft &&
      //     this.draft?.template &&
      //     (this.draft?.template?.panels || []).find(
      //       (i) => i.name == this.info.panelName
      //     )) ||
      //   null
      // );
    },
    panelOptions: {
      set(value) {
        this.panel.options = value;
      },
      get() {
        return this?.panel?.options || null;
      }
    },
    dataList: {
      set(value) {
        if (this?.panel?.options?.data) {
          this.panel.options.data = value;
        } else if (this.target?.data) {
          this.target.data = value;
        }
      },
      get() {
        return this?.panel?.options?.data || this.target?.data || [];
      }
    },
    dataId: {
      set(value) {
        let digits = (value + "").match(/\d+/g);
        if (digits) {
          value = parseInt(digits.join(""));
        } else {
          value = "";
        }
        this.$set(this, "curDataId", value || "");
      },
      get() {
        return this.curDataId || "";
      }
    },
    errors() {
      let entry = {
        cellStyle: "" // TODO: implement error parser
      };
      return entry;
    },
    titleText: {
      set(value) {
        if (this?.chartOptions?.title) {
          let chartOptions = {...this.chartOptions};
          chartOptions.title.text = value;
          this.chartOptions = chartOptions;
        }
      },
      get() {
        return this?.chartOptions?.title?.text || "";
      }
    },
    titleStyle: titleStyleProperty,
    titlePadding: {
      set(value) {
        this.echartPadding("title", value);
      },
      get() {
        return this.echartPadding("title");
      }
    },
    titlePaddingDisabled() {
      return disabledDimensions((this?.titleStyle || {})["text-align"] || "");
    },
    legendShow: {
      set(value) {
        if (this?.chartOptions?.legend) {
          let chartOptions = {...this.chartOptions};
          chartOptions.legend.show = value;
          this.chartOptions = chartOptions;
        }
      },
      get() {
        return this?.chartOptions?.legend?.show ?? false;
      }
    },
    legendDynamicColor: {
      set(value) {
        if (this?.chartOptions?.legend) {
          let chartOptions = {...this.chartOptions};
          chartOptions.legend.dynamicColor = value;
          this.chartOptions = chartOptions;
        }
      },
      get() {
        return this?.chartOptions?.legend?.dynamicColor ?? false;
      }
    },
    legendPadding: {
      set(value) {
        this.echartPadding("legend", value);
      },
      get() {
        return this.echartPadding("legend");
      }
    },
    legendPosition: {
      set(value) {
        if (this.chartOptions.legend) {
          let p = value.split("-");
          let chartOptions = {...this.chartOptions};
          chartOptions.legend.top = p[0];
          chartOptions.legend.left = p[1];
          this.chartOptions = chartOptions;
        }
      },
      get() {
        let p = this?.chartOptions?.legend || {top: "top", left: "left"};
        return `${p.top}-${p.left}`;
      }
    },
    legendPaddingDisabled() {
      // top,right,left,down
      return disabledDimensions(this.legendPosition);
    },
    equipmentDataList() {
      return this.$store.getters["dashboard/extendedDataList"] || [];
    },
    chartSeriesConfig: {
      set(value) {
        let options = {
          ...(this?.target || this?.panelOptions || {}),
          ...value,
          chartOptions: {
            ...(this?.panelOptions?.chartOptions || {}),
            ...(value?.chartOptions || {})
          }
        };
        if (this.target) {
          this.target = {...this.target, ...options};
        } else if (this.panelOptions) {
          this.panelOptions = {...this.panelOptions, ...options};
        }
      },
      get() {
        return this.target || this.panelOptions || null;
      }
    },
    chartOptions: {
      set(value) {
        let chartOptions = {
          ...(this?.chartSeriesConfig?.chartOptions || {}),
          ...(value || {})
        };
        this.chartSeriesConfig = {
          ...this.chartSeriesConfig,
          ...{chartOptions: chartOptions}
        };
      },
      get() {
        return this?.chartSeriesConfig?.chartOptions || null;
      }
    },
    disconnectionForm: {
      set(value) {
        this.panelOptions = {...this.panelOptions, ...(value || {})};
      },
      get() {
        return (this.panel && this.panel.options) || null;
      }
    },
    chartTooltipConfig: {
      set(value) {
        if (!this.chartOptions) return;
        let chartOptions = {...this.chartOptions};
        chartOptions.tooltip = {...value};
        this.chartOptions = chartOptions;
      },
      get() {
        return this?.chartOptions?.tooltip || {};
      }
    },
    nDataList() {
      return this?.dataList?.length || 0;
    },
    namedQuery() {
      return this?.panelOptions?.namedQuery || "";
    }
  },
  watch: {
    panelOptions: {
      handler(n, o) {
        if (!o || !n) return;
        this._savePanel();
      },
      deep: true
    },
    target: {
      handler(n, o) {
        if (!o || !n) return;
        this._updateParent();
      },
      deep: true
    }
  },
  methods: {
    echartPadding(nodeName, value) {
      // echarts padding order convertion setter/getter
      // from echarts: [up,right,down,left]
      //     to input: [top,right,left,down]
      let padding = [0, 0, 0, 0];
      if (value) {
        padding = [value[0] || 0, value[1] || 0, value[3] || 0, value[2] || 0];
        let chartOptions = {...this.chartOptions};
        chartOptions[nodeName].padding = padding;
        this.chartOptions = chartOptions;
      } else {
        padding = (this?.chartOptions || {})[nodeName]?.padding || padding;
        return [
          padding[0] || 0,
          padding[1] || 0,
          padding[3] || 0,
          padding[2] || 0
        ];
      }
    },
    onChartEvent($event) {
      switch ($event.action) {
        case "chart:activate": {
          this.info = $event.details;
          break;
        }
      }
    },
    dataListParser(lst) {
      return (lst || []).filter(
        ({id, history_enabled, type}) =>
          type !== "string" &&
          (`${id}`.includes("data_group") || history_enabled)
      );
    }
  },
  created() {
    if (this?.panel?.options?.chartOptions) {
      // this.chartOptions = structuredClone(this?.panel?.options?.chartOptions);
      this._savePanel = debounce(() => {
        // console.log("saved");
        this.$store.dispatch("dashboard/saveDraftPanel", {
          panel: this.panel,
          screenId: this.draft.screenId
        });
      }, 250);
    } else if (this.target?.chartOptions) {
      // this.chartOptions = structuredClone(this.target?.chartOptions || null);
      this._updateParent = debounce(() => {
        // console.log("parentUpdated");
        this.$emit("input", this.target);
      }, 250);
    } else {
      console.log("invalid configuration");
      return;
    }
    this.$root.$on("chart:event", this.onChartEvent);
  },
  beforeDestroy() {
    this.$root.$off("chart:event", this.onChartEvent);
  }
};
</script>

<style scoped>
section.main {
  padding-bottom: 15px;
}
label {
  margin-bottom: 0;
}

.tab-content {
  background: white;
}

.inner-panel {
  padding: 10px 0 0 10px;
}

.inner-panel > * {
  margin-bottom: 10px;
}

.clicable:hover {
  cursor: pointer;
  opacity: 0.8;
}

.data-toggle-link {
  padding: 2px;
}

.data-toggle {
  position: absolute;
  right: 2px;
  top: 0;
  padding: 2px;
}

.content-padding {
  padding-top: 5px;
  padding-bottom: 5px;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

div.checkbox > div.checkbox > label {
  margin-left: 20px;
}
div.inner-box {
  padding: 10px;
  border: 1px solid lightgray;
  border-radius: 5px;
  margin-top: -5px;
}
</style>
