<template>
  <span>
    <slot name="toggle">
      <span
        class="btn btn-default"
        :class="floatPanel.open ? 'active' : ''"
        @click.stop.prevent="toggle"
      >
        {{ $t($attrs.title) }}
      </span>
    </slot>
    <portal to="contextmenu" v-if="floatPanel.open">
      <FloatPanel
        class="float-panel float-panel-data-simulator"
        :draggable="true"
        :handleSelf="true"
        :defaultPosition="{top: floatPanel.top, left: floatPanel.left}"
        :open="floatPanel.open"
        :title="$attrs.title"
        @update:open="onFloatPanelUpdate($event)"
        @dragstart="floatPanel.dragging = true"
        @dragend="onDragEnd(floatPanel, $event)"
      >
        <div class="popup">
          <div class="popup-body">
            <slot></slot>
          </div>
        </div>
      </FloatPanel>
    </portal>
  </span>
</template>

<script>
import FloatPanel from "@/components/editor/float-panel.vue";
const _fpsize = [500, 200];
let _fp = {
  h: _fpsize[1],
  w: _fpsize[0],
  y: parseInt((window.innerHeight - _fpsize[1]) / 2) - 150,
  x: parseInt((window.innerWidth - _fpsize[0]) / 2)
};
_fp.y = _fp.y < window.innerHeight ? _fp.y : parseInt(window.innerHeight * 0.8);
_fp.x = _fp.x < window.innerWidth ? _fp.x : parseInt(window.innerWidth * 0.8);

export default {
  name: "FloatPanelToggle",
  components: {
    FloatPanel
  },
  data() {
    return {
      busy: false,
      floatPanel: {
        top: `${_fp.y}px`,
        left: `${_fp.x}px`,
        open: false,
        dragging: false
      }
    };
  },
  methods: {
    onFloatPanelUpdate(value) {
      if (this.floatPanel.open != value) {
        this.floatPanel.open = value;
      }
    },
    toggle() {
      this.floatPanel.open = !this.floatPanel.open;
      if (this.floatPanel.open) {
        // this.busy = true;
      }
    },
    onDragEnd(floatPanel, $event) {
      floatPanel.top = $event.top;
      floatPanel.left = $event.left;
      floatPanel.dragging = false;
    }
  }
};
</script>

<style scoped>
.popup {
  position: relative;
}

.popup > .popup-body {
  width: 500px;
  min-width: 500px;
  min-height: 200px;
  max-height: 60vh;
  resize: both;
  overflow: auto;
  border-top: 1px solid lightgray;
  display: flex;
  align-items: stretch;
  padding: 5px;
}

.popup > .popup-body:hover {
  cursor: default;
}
</style>