<template>
  <section>
    <div class="row">
      <div class="col-xs-12">
        <label for="">{{ $tc("tab_label.custom_fields", 2) }}</label>
        <div class="form-group ">
          <label class="checkbox-inline" for="showRequiredFields">
            <input
              type="checkbox"
              v-model="showRequiredFields"
              id="showRequiredFields"
            />
            <span>
              {{ $t("show") }} {{ $tc("required", 2).toLowerCase() }}
            </span>
          </label>
        </div>
        <div class="form-group ">
          <label class="checkbox-inline" for="showOptionalFields">
            <input
              type="checkbox"
              v-model="showOptionalFields"
              id="showOptionalFields"
            />
            <span>
              {{ $t("show") }} {{ $tc("optional", 2).toLowerCase() }}
            </span>
          </label>
        </div>
        <AlertForm v-model="confirmation" />
      </div>
    </div>
  </section>
</template>

<script>
import AlertForm from "@/components/editor/alert-form.vue";
export default {
  name: "DetailFormExtendedPropertiesPanel",
  components: {
    AlertForm
  },
  computed: {
    panel() {
      return this.$store.getters["dashboard/currentDraftPanel"] || null;
    },
    screenId() {
      return (this.$store.getters["dashboard/draft"] || {screenId: ""})
        .screenId;
    },
    showRequiredFields: {
      set(value) {
        if (this?.panel?.options) {
          let panel = this.panel;
          panel.options.showRequiredFields = value;
          this.$store.dispatch("dashboard/saveDraftPanel", {
            screenId: this.screenId,
            panel: panel
          });
        }
      },
      get() {
        return this?.panel?.options?.showRequiredFields ?? false;
      }
    },
    showOptionalFields: {
      set(value) {
        if (this?.panel?.options) {
          let panel = this.panel;
          panel.options.showOptionalFields = value;
          this.$store.dispatch("dashboard/saveDraftPanel", {
            screenId: this.screenId,
            panel: panel
          });
        }
      },
      get() {
        return this?.panel?.options?.showOptionalFields ?? false;
      }
    },
    confirmation: {
      set(value) {
        let panel = this.panel;
        let ix = (panel?.options?.controls || []).findIndex((item) =>
          (item?.on?.click?.actions || []).some(
            ({type}) => type == "form:submit"
          )
        );
        if (ix == -1) return;
        let ctrl = (panel?.options?.controls || [])[ix];
        ctrl.on = ctrl.on || {};
        ctrl.on.click = ctrl.on.click || {};
        ctrl.on.click.confirmation = {
          ...(ctrl?.on?.click?.confirmation || null),
          ...(value || null)
        };
        this.$set(panel.options?.controls, ix, ctrl);
        this.$store.dispatch("dashboard/saveDraftPanel", {
          screenId: this.screenId,
          panel: panel
        });
      },
      get() {
        return (
          (this?.panel?.options?.controls || []).find((item) =>
            (item?.on?.click?.actions || []).some(
              ({type}) => type == "form:submit"
            )
          )?.on?.click?.confirmation ?? null
        );
      }
    }
  },
  methods: {
    reset() {
      this.src = "";
      this.$nextTick(() => {
        this.$refs.inp.focus();
      });
    }
  }
};
</script>

<style scoped>
.checkbox-inline > span {
  margin-left: 3px;
  font-weight: 700;
}
</style>
