<template>
  <!-- Font family selection -->
  <div
    class="form-group form-group-sm"
    v-if="style['font-family'] || style['font-size']"
  >
    <div>
      <label
        class="label-inline clicable"
        @click.stop.prevent="editing = !editing"
      >
        <i
          :class="editable ? 'fa fa-check-square-o' : 'fa fa-square-o'"
          style="margin-right: 5px"
        ></i>
        <span>
          {{ $t("synoptic.font") }}
          <small v-if="editable">({{ style["font-size"] }}) </small>
        </span>
      </label>
      <div class="pull-right" v-if="editable">
        <span class="btn btn-xs" @click.stop.prevent="toogleFontUnit"
          >{{ fontSizeUnit }}
        </span>
      </div>
      <Tooltip :title="$t('hints.font')" class="label-hint" />
    </div>
    <div v-if="editable">
      <div class="input-group">
        <select
          class="form-control"
          v-model="style['font-family']"
          style="font-weight: 600; font-size: 10pt; padding: 0"
          :style="{'font-family': style['font-family']}"
        >
          <option
            v-for="(font, index) in fonts"
            :value="font.value"
            :key="index"
            :style="{'font-family': font.value}"
          >
            {{ font.name }}
          </option>
        </select>
        <div class="input-group-addon no-padding">
          <input
            class="input-font-size"
            list="fontSizeList"
            v-model="fontSize"
            onfocus="this.value=''"
            @blur="onFontSizeBlur"
            @keydown.esc="onFontSizeBlur"
          />
          <datalist id="fontSizeList">
            <option
              :value="size"
              v-for="(size, ix) in fontSizeList"
              :key="ix"
            />
          </datalist>
        </div>
      </div>
      <div class="btn-group btn-group-justified" role="group" aria-label="Font">
        <div class="btn-group" role="group">
          <button
            class="btn btn-xs"
            :class="
              style['font-weight'] == 'bold' ? 'btn-primary' : 'btn-default'
            "
            @click="toggleBold"
          >
            <span class="glyphicon glyphicon-bold"></span>
          </button>
        </div>
        <div class="btn-group" role="group">
          <button
            class="btn btn-xs"
            :class="
              style['font-style'] == 'italic' ? 'btn-primary' : 'btn-default'
            "
            @click="toggleItalic"
          >
            <span class="glyphicon glyphicon-italic"></span>
          </button>
        </div>
        <template v-if="style['text-decoration']">
          <div class="btn-group" role="group">
            <button
              type="button"
              class="btn btn-xs"
              :class="
                (style['text-decoration'] || '').includes('underline')
                  ? 'btn-primary'
                  : 'btn-default'
              "
              @click="toggleUnderline"
            >
              <span class="fa fa-underline"></span>
            </button>
          </div>
          <div class="btn-group" role="group">
            <button
              type="button"
              class="btn btn-xs"
              :class="
                (style['text-decoration'] || '').includes('line-through')
                  ? 'btn-primary'
                  : 'btn-default'
              "
              @click="toggleLinethrough"
            >
              <span class="fa fa-strikethrough"></span>
            </button>
          </div>
        </template>
      </div>
    </div>
    <input
      v-else
      type="text"
      class="form-control text-center"
      disabled
      :value="$t('standard')"
    />
  </div>
</template>

<script>
import Tooltip from "@/components/tooltip.vue";
const dftFontStyle = () => ({
  "font-family": "Source Sans Pro",
  "font-size": "18pt",
  "font-style": "normal",
  "font-weight": "normal",
  "text-decoration": "none"
});
export default {
  name: "FontSelector",
  components: {
    Tooltip
  },
  props: {
    value: {
      type: Object,
      required: false,
      default: null
    },
    editable: {
      type: Boolean,
      required: false,
      default: true
    },
    allowedUnits: {
      type: Array,
      required: false,
      default: () => ["%", "px", "pt"]
    },
    noTextDecoration: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      style: {},
      fonts: [
        {name: "Arial", value: "Arial, Helvetica, sans-serif"},
        {name: "Arial Black", value: '"Arial Black", Gadget, sans-serif'},
        {
          name: "Comic Sans MS",
          value: '"Comic Sans MS", cursive, sans-serif'
        },
        {name: "Courier New", value: '"Courier New", Courier, monospace'},
        {name: "Georgia", value: "Georgia, serif"},
        {name: "Impact", value: "Impact, Charcoal, sans-serif"},
        {
          name: "Lucida Console",
          value: '"Lucida Console", Monaco, monospace'
        },
        {
          name: "Lucida Sans Unicode",
          value: '"Lucida Sans Unicode", "Lucida Grande", sans-serif'
        },
        {
          name: "Palatino Linotype",
          value: '"Palatino Linotype", "Book Antiqua", Palatino, serif'
        },
        {
          name: "Source Sans Pro",
          value: '"Source Sans Pro", sans-serif'
        },
        {name: "Tahoma", value: "Tahoma, Geneva, sans-serif"},
        {name: "Times New Roman", value: '"Times New Roman", Times, serif'},
        {
          name: "Trebuchet MS",
          value: '"Trebuchet MS", Helvetica, sans-serif'
        },
        {name: "Verdana", value: "Verdana, Geneva, sans-serif"}
      ],
      fontSizeUnit: "%",
      applicableStyleEnabled: true
    };
  },
  computed: {
    editing: {
      set(value) {
        this.$emit("input", value ? this.style : null);
      },
      get() {
        return this.editable;
      }
    },
    fontSizeList() {
      return this.fontSizeUnit == "%"
        ? [20, 30, 40, 50, 60, 65, 70, 75, 80, 85, 90, 95, 100, 110, 120]
        : [
            6,
            7,
            8,
            9,
            10,
            11,
            12,
            14,
            16,
            18,
            24,
            32,
            60,
            72,
            84,
            96,
            108,
            120
          ];
    },
    fontSize: {
      set(value) {
        if (value)
          this.style["font-size"] =
            `${value}`.replace(/\D+/, "") + this.fontSizeUnit;
      },
      get() {
        return `${this.style["font-size"]}`.replace(/\D+/, "");
      }
    }
  },
  watch: {
    style: {
      handler(n) {
        this.$emit("input", this.editable ? n : null);
      },
      deep: true
    }
  },
  methods: {
    onFontSizeBlur(ev) {
      if (!ev.target.value) {
        ev.target.value = this.fontSize;
      }
    },
    parseFontFamily(val) {
      let font = this.fonts.find(({value}) => value.includes(val));
      if (!font) font = this.fonts[0];
      this.style["font-family"] = font.value;
    },
    toggleBold() {
      this.$set(
        this.style,
        "font-weight",
        this.style["font-weight"] == "bold" ? "normal" : "bold"
      );
    },
    toggleItalic() {
      this.$set(
        this.style,
        "font-style",
        this.style["font-style"] == "italic" ? "normal" : "italic"
      );
    },
    toggleUnderline() {
      if ((this.style["text-decoration"] || "").includes("underline")) {
        this.style["text-decoration"] = this.style["text-decoration"]
          .replace("underline", "")
          .trim();
        if (!this.style["text-decoration"])
          this.style["text-decoration"] = "none";
      } else {
        this.$set(
          this.style,
          "text-decoration",
          (
            (this.style["text-decoration"] || "").replace("none", "").trim() +
            " underline"
          ).trim()
        );
      }
    },
    toggleLinethrough() {
      if ((this.style["text-decoration"] || "").includes("line-through")) {
        this.style["text-decoration"] = this.style["text-decoration"]
          .replace("line-through", "")
          .trim();
        if (!this.style["text-decoration"])
          this.style["text-decoration"] = "none";
      } else {
        this.$set(
          this.style,
          "text-decoration",
          (
            (this.style["text-decoration"] || "").replace("none", "").trim() +
            " line-through"
          ).trim()
        );
      }
    },
    toogleFontUnit() {
      this.fontSizeUnit = this.nextUnits[this.fontSizeUnit];
      this.fontSize = this.fontSize;
    }
  },
  created() {
    let nextUnits = {pt: "px", px: "%", "%": "pt"};
    if (this.allowedUnits.length > 1) {
      for (var k in nextUnits)
        if (!this.allowedUnits.includes(k)) delete nextUnits[k];
      this.nextUnits = nextUnits;
    } else if (this.allowedUnits.length == 1) {
      this.nextUnits = {[this.allowedUnits[0]]: this.allowedUnits[0]};
    } else {
      this.nextUnits = nextUnits;
    }
    let style = {...dftFontStyle(), ...(this.value || {})};
    if (this.noTextDecoration) delete style["text-decoration"];
    this.$set(this, "style", style);
    this.fontSizeUnit = `${this.style["font-size"] || "%"}`.replace(/\d+/, "");
    if (!this.allowedUnits.includes(this.fontSizeUnit)) {
      this.fontSizeUnit = this.allowedUnits[0];
    }
    this.parseFontFamily(this.style["font-family"]);
  }
};
</script>

<style scoped>
.no-padding {
  padding: 0;
}
.label-inline {
  margin-right: 5px;
}

.form-group > .form-control {
  /* margin-left: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem; */
}

.input-font-size {
  border: 0;
  width: 56px;
  min-height: 16px;
  padding: 0;
  text-align: center;
}

.input-font-size:hover {
  appearance: menulist !important;
  -moz-appearance: menulist !important;
  -webkit-appearance: menulist !important;
  -o-appearance: menulist !important;
}

.input-font-size:focus {
  border: 0;
  outline: none;
  appearance: menulist !important;
  -moz-appearance: menulist !important;
  -webkit-appearance: menulist !important;
  -o-appearance: menulist !important;
}
</style>
