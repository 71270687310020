<template>
  <span>
    <ul class="nav navbar-nav hidden-xs">
      <li class="">
        <div class="input-group screen-name">
          <div class="input-group-btn">
            <button
              type="button"
              class="btn btn-default dropdown-toggle"
              data-toggle="dropdown"
              aria-expanded="false"
            >
              <span class="fa fa-caret-down"></span>
            </button>
            <ul class="dropdown-menu">
              <template v-for="(option, ix) in fileMenuOptions">
                <li :key="ix" v-if="!isNew || option.visible_if_new">
                  <a
                    href="javascript:void(0)"
                    @click.prevent="onCommand(option.command)"
                  >
                    <DashboardDownloadBtn
                      v-if="option.command == 'download'"
                      :screenId="screen.id"
                      style="color: inherit"
                    >
                      <template>
                        <i
                          :class="
                            option.busy ? 'fa fa-refresh fa-spin' : option.icon
                          "
                        ></i>
                        <span>
                          {{ $t(option.title) }}
                        </span>
                      </template>
                    </DashboardDownloadBtn>
                    <span v-else>
                      <i :class="option.icon"></i>
                      <span>
                        {{ $tc(option.title, 1) }}
                      </span>
                    </span>
                  </a>
                </li>
              </template>
              <template v-if="recentList.length">
                <li class="divider"></li>
                <li class="dropdown-submenu">
                  <a
                    tabindex="-1"
                    href="javascript:void(0)"
                    @click.stop.prevent
                    >{{ $tc("recent", 1) }}</a
                  >
                  <ul class="dropdown-menu">
                    <li v-for="screen in recentList" :key="screen.id">
                      <a
                        tabindex="-1"
                        href="javascript:void(0)"
                        @click="openDashboard(screen)"
                        :title="
                          `ID: ${screen.id}\nLast Update: ${screen.ts}\nVersion: ${screen.revision_code}`
                        "
                      >
                        {{ $utils.proper(screen.name) }}
                      </a>
                    </li>
                  </ul>
                </li>
              </template>
            </ul>
          </div>
          <input
            type="text"
            class="form-control"
            v-model="title"
            :style="{color: isNew ? '#a94442' : 'inherit'}"
            :title="isNew ? `${$t('new_screen')} [${screen.id}]` : title"
          />
          <div
            v-if="!isUpdated"
            class="out-of-date"
            :title="$t('old_version_alert', {version: ''})"
          >
            <i class="fa fa-exclamation-triangle text-danger"></i>
          </div>
        </div>
      </li>
      <li class="hidden-md hidden-lg">
        <div class="menu">
          <ul class="nav navbar-nav">
            <li class="dropdown header-button">
              <div
                role="button"
                class="dropdown-toggle"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                style="padding-left: 0"
              >
                <span class="btn">
                  <i class="fa fa-sliders"></i>
                </span>
              </div>
              <ul class="dropdown-menu">
                <li
                  class="clicable no-select"
                  :class="option.command == active ? 'active btn-primary' : ''"
                  :key="option.command"
                  :title="$t(option.hint)"
                  v-for="option in toolBarOptions"
                >
                  <a href="javascript:void(0)">
                    <DashboardDownloadBtn
                      v-if="option.command == 'download'"
                      :screenId="screen.id"
                    >
                      <template>
                        <i
                          :class="
                            option.busy ? 'fa fa-refresh fa-spin' : option.icon
                          "
                        ></i>
                        <span>
                          {{ $t(option.title) }}
                        </span>
                      </template>
                    </DashboardDownloadBtn>
                    <span v-else @click.prevent="onCommand(option.command)">
                      <i
                        :class="
                          option.busy ? 'fa fa-refresh fa-spin' : option.icon
                        "
                      ></i>
                      <span>
                        {{ $tc(option.title, 1) }}
                      </span>
                    </span>
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </li>
      <li class="hidden-xs hidden-sm">
        <div class="menu">
          <ul class="nav navbar-nav">
            <li
              class="clicable no-select"
              :key="option.command"
              :title="$t(option.hint)"
              v-for="option in toolBarOptions"
            >
              <template v-if="!isNew || option.visible_if_new">
                <div v-if="option.command == 'download'">
                  <DashboardDownloadBtn :screenId="screen.id">
                    <template>
                      <span class="btn btn-xs">
                        <i
                          :class="
                            option.busy ? 'fa fa-refresh fa-spin' : option.icon
                          "
                        ></i>
                        <span class="hidden-sm hidden-md">
                          {{ $tc(option.title, 1) }}
                        </span>
                      </span>
                    </template>
                  </DashboardDownloadBtn>
                </div>
                <div v-else>
                  <span
                    class="btn"
                    :class="
                      option.command == active ? 'active btn-primary' : ''
                    "
                    @click.stop.prevent="onCommand(option.command)"
                  >
                    <i
                      :class="
                        option.busy ? 'fa fa-refresh fa-spin' : option.icon
                      "
                    ></i>
                    <span class="hidden-sm hidden-md">
                      {{ $tc(option.title, 1) }}
                    </span>
                  </span>
                </div>
              </template>
            </li>
            <!-- <li class="clicable" :title="$t('simulator')">
              <FloatPanelToggle :title="$t('simulator')" ref="simulation">
                <template #toggle>
                  <div style="margin: 6px 0 0 0">
                    <span
                      class="btn btn-xs"
                      @click.stop.prevent="
                        $refs.simulation && $refs.simulation.toggle()
                      "
                    >
                      <i class="text-primary glyphicon glyphicon-transfer"></i>
                    </span>
                  </div>
                </template>
                <template>
                  <DashboardDataValueSimulationForm />
                </template>
              </FloatPanelToggle>
            </li> -->
          </ul>
        </div>
      </li>
    </ul>
    <DashboardChangesTracker v-if="isEditing" style="margin: 16px 0 0 16px" />
    <portal to="modal">
      <PublishForm
        v-if="modal == 'publish'"
        @close="setCommandState()"
        @saved="onClosePublishDialog($event)"
        :screenId="screen.id || ''"
        :isNew="isNew"
      />
      <UnpublishForm
        v-else-if="modal == 'unpublish'"
        @close="setCommandState()"
        @removed="onRemoved"
        :screenId="screen.id || ''"
      />
      <SaveAsForm
        v-else-if="modal == 'saveas'"
        @close="onCloseSaveAsDialog($event)"
        :screenId="screen.id || ''"
      />
      <RestoreScreenDialog
        v-else-if="modal == 'versions'"
        @close="onCloseRestoreDialog($event)"
        :screenId="screen.id"
      />
      <ImportForm
        v-else-if="modal == 'import'"
        @close="onCloseImportDialog($event)"
        :screenId="screen.id || ''"
      />
      <ScreenSearchTable
        v-else-if="modal == 'select'"
        :modal="true"
        :items="screens"
        @close="onCloseImportDialog($event)"
        @select="openDashboard($event)"
      />
    </portal>
    <FloatPanelToggle :title="$t('simulator')" ref="simulation">
      <template #toggle>
        <span></span>
      </template>
      <template>
        <DashboardDataValueSimulationForm />
      </template>
    </FloatPanelToggle>
  </span>
</template>

<script>
import PublishForm from "@/components/editor/publish-form.vue";
import UnpublishForm from "@/components/editor/unpublish-form.vue";
import SaveAsForm from "@/components/editor/save-as-form.vue";
import RestoreScreenDialog from "@/components/editor/restore-screen-dialog.vue";
import DashboardDownloadBtn from "@/components/editor/dashboard-download-btn.vue";
import ImportForm from "@/components/editor/import-form.vue";
import ScreenSearchTable from "@/components/editor/screen-search-table.vue";
import DashboardChangesTracker from "@/components/editor/dashboard-changes-tracker.vue";
import FloatPanelToggle from "@/components/editor/float-panel-toggle.vue";
import DashboardDataValueSimulationForm from "@/components/editor/dashboard-data-value-simulation-form";

export default {
  name: "DashboardEditorToolbar",
  props: {
    screen: {
      type: Object,
      required: true,
      default: () => null
    }
  },
  components: {
    RestoreScreenDialog,
    PublishForm,
    UnpublishForm,
    SaveAsForm,
    DashboardDownloadBtn,
    ImportForm,
    ScreenSearchTable,
    DashboardChangesTracker,
    FloatPanelToggle,
    DashboardDataValueSimulationForm
  },
  data: () => ({
    active: "",
    modal: "",
    fileMenuOptions: [
      {
        title: "new",
        icon: "fa fa-plus-square-o",
        command: "handleNew",
        busy: false,
        visible_if_new: true
      },
      {
        title: "open",
        icon: "fa fa-folder-o",
        command: "handleDashboardSelection",
        busy: false,
        visible_if_new: true
      },
      {
        title: "import",
        icon: "fa fa-upload",
        command: "import",
        busy: false,
        visible_if_new: true
      },
      {
        title: "export",
        icon: "fa fa-download",
        command: "download",
        busy: false,
        visible_if_new: true
      },
      {
        title: "clone",
        icon: "fa fa-copy",
        command: "clone",
        busy: false,
        visible_if_new: true
      },
      // {
      //   title: "restore",
      //   icon: "fa fa-undo",
      //   command: "restore",
      //   busy: false,
      //   visible_if_new: false
      // },
      {
        title: "simulator",
        icon: "glyphicon glyphicon-transfer",
        command: "simulationToggle",
        busy: false,
        visible_if_new: true
      },
      {
        title: "versions",
        icon: "fa fa-history",
        command: "versions",
        busy: false,
        visible_if_new: false
      }
      // ,
      // {
      //   title: "properties",
      //   icon: "fa fa-sliders",
      //   command: "handleDashboardProperties",
      //   busy: false,
      //   visible_if_new: true
      // }
    ]
  }),
  computed: {
    screens() {
      return this.$store.getters["dashboard/screens"] || [];
    },
    draft() {
      return this.$store.getters["dashboard/draft"] || null;
    },
    template() {
      return this?.draft?.template || null;
    },
    title: {
      set(value) {
        let tpl = JSON.parse(JSON.stringify(this.template));
        tpl.title = value;
        this.$store.dispatch("dashboard/saveDraft", {
          screenId: this.screen.id,
          template: tpl
        });
      },
      get() {
        let value =
          this?.template?.title == "Untitled screen"
            ? this?.screen?.name
            : this.isNew && this?.template?.title == "screen_name"
            ? this.$t("screen_name")
            : this?.template?.title;
        return value; //this.$t(value);
      }
    },
    isNew() {
      return this.screen.id < 0;
    },
    recentList() {
      return (this.$store.getters["dashboard/editorSettings"].recent || [])
        .map((item) => {
          var entry = {
            ...{ts: item.updated_at, name: null},
            ...(this.screens || []).find(({id}) => id == item.screenId)
          };
          if (entry.id <= 0 && !entry.name) {
            entry.name = `${this.$t("new_screen")} (${this.$utils.sprintf(
              "%02d",
              Math.abs(entry.id)
            )})`;
          }
          return entry;
        })
        .filter(({name, id}) => name != null && id != this.screen.id);
    },
    isUpdated() {
      if (this.isNew) return true;
      let etag = this?.draft?.template?.draft?.etag || "";
      return !etag || !this?.screen?.etag || etag == this.screen.etag;
    },
    isEditing() {
      return (
        this?.template?.layout?.length &&
        !this.$store.getters["dashboard/currentDraftPanel"]
      );
    },
    toolBarOptions() {
      let lst = [
        {
          title: "screen",
          icon: "fa fa-desktop",
          command: "handleDashboardProperties",
          busy: false,
          visible_if_new: true,
          hint: "hints.editor.screen"
        },
        {
          title: "layout",
          icon: "fa fa fa-columns",
          command: "handleDashboardLayout",
          busy: false,
          visible_if_new: true,
          hint: "hints.editor.layout"
        },
        {
          title: "restore",
          icon: "fa fa-undo",
          command: "restore",
          busy: false,
          visible_if_new: false,
          hint: "hints.editor.restore"
        },
        {
          title: "view",
          icon: "fa fa-dashboard",
          command: "view",
          busy: false,
          visible_if_new: true,
          hint: "hints.editor.preview"
        },
        {
          title: this.$tc("publish", 1),
          icon: "fa fa-cloud-upload",
          command: "publish",
          busy: false,
          visible_if_new: true,
          hint: "hints.editor.publish"
        }
      ];
      if (
        this.screen.deleted_at ||
        (this.screen.portal_data && this.screen.portal_data.excluded_at)
      ) {
        lst = lst.filter(({command}) => command != "publish");
      }
      return lst;
    }
  },
  methods: {
    onCommand(command) {
      if (!(command in this && typeof this[command] == "function")) return;
      this.active = command;
      this[command]();
    },
    handleNew() {
      this.$root.$emit("dashboard:editor", {action: "newDashboard"});
      this.setCommandState();
    },
    handleDashboardProperties() {
      this.editDashboard();
      this.setDrawerTab("component");
    },
    handleDashboardLayout() {
      this.editDashboard();
      this.setDrawerTab("components");
    },
    editDashboard() {
      this.active = "";
      this.modal = "";
      this.$root.$emit("dashboard:editor", {action: "editDashboard"});
    },
    setDrawerTab(which) {
      this.$nextTick(() => {
        this.$root.$emit("drawer:event", {
          action: "tab:activate",
          details: which
        });
      });
    },
    view() {
      this.active = "";
      this.modal = "";
      this.$root.$emit("dashboard:editor", {action: "previewDashboard"});
    },
    import() {
      this.modal = "import";
    },
    clone() {
      this.modal = "saveas";
    },
    handleDashboardSelection() {
      this.modal = "select";
    },
    openDashboard(screen) {
      if (screen) {
        this.$root.$emit("dashboard:editor", {
          action: "openDashboard",
          data: {screenId: screen.id}
        });
      }
    },
    setCommandState(name, option) {
      if (name) {
        let item = this.toolBarOptions.find(({command}) => command == name);
        if (item) {
          item.busy = option || false;
        }
      } else {
        this.active = "";
        this.modal = "";
      }
    },
    publish() {
      // if (!isNew) this.selectScreen(screen);
      // this.isNew = isNew;
      this.modal = "publish";
    },
    _restore() {
      let self = this;
      self.setCommandState("restore", true);
      let _fetch = async () => {
        let template = await self.$store.dispatch("dashboard/fetchTemplate", {
          screenId: self.screen.id,
          updateStatus: false
        });
        self.$store.dispatch("synoptic/removeDraft").then(() => {
          if (template) {
            self.$store.commit("dashboard/SAVE_DRAFT", {
              screenId: self.screen.id,
              template: null
            });
            self.$root.$emit("refreshPage");
          }
          self.setCommandState("restore", false);
          self.$nextTick(() => {
            self.$root.$emit("dashboard:editor", {action: "editDashboard"});
          });
        });
      };
      _fetch();
    },
    restore() {
      let self = this;
      let draft = this.$store.getters["dashboard/draft"] || null;
      if (draft && draft?.template?.draft?.removed) {
        for (var key in draft?.template?.draft?.removed) {
          let panel = draft.template.draft.removed[key];
          if (panel && panel.template == this.panelComponentType) {
            delete draft.template.draft.removed[key];
            // _fetch(this, this.screen.id);
            this._restore();
            return;
          }
        }
      }

      // requires confirmation
      this.alertWrapper.innerHTML =
        "<div style='margin-top:-5px;'><b>" +
        self.$t("restore_screen") +
        "</b></div>" +
        "<div style='margin-top:15px;'>" +
        self.$t("this_will_reset_all_of_your_unpushed_changes") +
        "</div>" +
        "<div class='text-danger' style='margin-top:5px;'><i class='fa fa-exclamation-triangle'></i> " +
        self.$t("you_wont_be_able_to_revert_this") +
        "</div>";
      this.$swal({
        title: self.$t("are_you_sure"),
        content: this.alertWrapper,
        icon: "warning",
        buttons: [self.$t("cancel"), self.$t("confirm")]
      }).then((option) => {
        this.modal = "";
        this.active = "";
        if (option) {
          // _fetch(this, this.screen.id);
          self._restore();
        }
      });
    },
    versions() {
      this.modal = "versions";
    },
    onCloseRestoreDialog(goto_path) {
      this.setCommandState();
      if (goto_path) {
        this.$forceUpdate();
        if (this.$route.path == goto_path) {
          this.$root.$emit("refreshPage");
        } else {
          this.busy = true;
          setTimeout(
            () => {
              this.busy = false;
              this.$router.push(goto_path);
            },
            500,
            this
          );
        }
      } else {
        this.onCloseModal();
      }
    },
    onCloseImportDialog(screenId) {
      this.onCloseModal(screenId);
      // additional code
    },
    onClosePublishDialog(screen) {
      this.onCloseModal(screen?.id);
      // additional code
    },
    onCloseSaveAsDialog(screenId) {
      this.onCloseModal(screenId);
      // additional code
    },
    onCloseModal(screenId) {
      // screenId might be different from the current one
      this.setCommandState();
      if (screenId && screenId != this.screen.id) {
        this.$store.commit("dashboard/RESET_DRAFT");
        this.$store.dispatch("dashboard/initDraft", screenId);
        this.$router.push(`/dashboard/screen/edit/${screenId}`);
      } else {
        this.$root.$emit("refreshPage");
      }
    },
    simulationToggle() {
      this.$refs.simulation && this.$refs.simulation.toggle();
    }
  },
  created() {
    this.alertWrapper = document.createElement("div");
    this.alertWrapper.id = this.$utils.uuid().replace(/-/g, "");
    if (this?.template && this.screen) {
      let value =
        this?.template?.title == "Untitled screen"
          ? this?.screen?.name
          : this?.template?.title;
      this.title = this.$t(value || "screen_name");
    }
  }
};
</script>

<style scoped>
.screen-name {
  padding: 8px 0 8px 15px;
  position: relative;
}

.screen-name > .input-group-btn {
  width: 24px;
}

.screen-name > .input-group-btn > button {
  background-color: transparent;
  border: 0;
}

.screen-name > input {
  max-width: 200px;
  min-width: 20px;
  width: auto;
  border-radius: 3px;
  border-color: transparent;
  background-color: transparent;
  font-size: 14pt;
  display: inline-block;
  padding-left: 0;
}

.screen-name > input:hover {
  border-color: #ccc;
}

.screen-name > span {
  position: absolute;
  left: 48px;
  top: 16px;
  font-size: 50%;
  color: rgb(169, 68, 66);
}

.out-of-date {
  position: absolute;
  right: 5px;
  top: 8px;
  z-index: 9;
  opacity: 0.7;
}

.menu > ul > li > div {
  padding: 10px 0 0 20px;
}

.btn {
  font-size: 11.5pt;
}
.btn > i {
  margin-right: 3px;
}

ul.dropdown-menu > li {
  /* padding: 4px 0px 0 10px; */
}

ul.dropdown-menu > li > span > i {
  margin-right: 2px;
  min-width: 18px;
}

.dropdown-menu > li > a {
  padding: 3px 10px;
}
.dropdown-menu > li > a > span > i,
.dropdown-menu > li > a > div > span > i {
  width: 20px;
}

.box-tools > .dropdown-menu > li > a > i {
  width: 10px;
}

.clicable:hover {
  opacity: 0.8;
  cursor: pointer;
}

/* dropdown submenu  begin*/

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu > .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -6px;
  margin-left: -1px;
  -webkit-border-radius: 0 6px 6px 6px;
  -moz-border-radius: 0 6px 6px;
  border-radius: 0 6px 6px 6px;
}

.dropdown-submenu > .dropdown-menu > li > a {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.dropdown-submenu:hover > .dropdown-menu {
  display: block;
}

.dropdown-submenu > a:after {
  display: block;
  content: " ";
  float: right;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  border-left-color: #999;
  margin-top: 5px;
  margin-right: -10px;
}

.dropdown-submenu:hover > a:after {
  border-left-color: #fff;
}

.dropdown-submenu.pull-left {
  float: none;
}

.dropdown-submenu.pull-left > .dropdown-menu {
  left: -100%;
  margin-left: 10px;
  -webkit-border-radius: 6px 0 6px 6px;
  -moz-border-radius: 6px 0 6px 6px;
  border-radius: 6px 0 6px 6px;
}
/* dropdown submenu  end*/
</style>
