<template>
  <TogglePanel :title="$t('disconnection')" v-if="value">
    <div class="form-group form-group-sm">
      <div class="checkbox">
        <label>
          <input type="checkbox" v-model="formData.showDisconnection" />
          {{ $t("titles.show_disconnection") }}
          <Tooltip :title="$t('hints.show_disconnection')" />
        </label>
      </div>
    </div>
    <div v-if="formData.showDisconnection" class="inner-box">
      <div>
        <div class="form-group form-group-sm">
          <label for="disconnection_threshold"
            >{{ $t("titles.disconnection_threshold") }}
            <Tooltip :title="$t('hints.disconnection_threshold')" />
          </label>
          <select
            v-model="formData.disconnectionThreshold"
            id="disconnection_threshold"
            class="form-control"
          >
            <option value="0">{{ $t("titles.any") }}</option>
            <option
              v-for="option in options"
              :value="option.value"
              :key="option.value"
            >
              {{ option.label }}
            </option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-4">
          <div class="form-group">
            <label style="padding-right: 4px;white-space:nowrap;">
              {{ $tc("color", 1) }}
              <Tooltip :title="$t('hints.disconnection_color')" />
            </label>
            <ColorPicker
              v-model="formData.disconnectionColor"
              :pickerStyle="{left: '-200px'}"
              icon="foreground"
            />
          </div>
        </div>
        <div class="col-xs-8">
          <div class="form-group form-group-sm">
            <label for="disconnectedDataLine">
              {{ $tc("line", 1) }} / {{ $tc("data", 1) }}
              <Tooltip :title="$t('hints.disconnection_data_line')" />
            </label>
            <select
              class="form-control"
              id="disconnectedDataLine"
              v-model="formData.disconnectedDataLine"
            >
              <option value="trend">{{ $tc("titles.trend", 1) }}</option>
              <option value="none">{{ $tc("none", 1) }}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </TogglePanel>
</template>

<script>
import ColorPicker from "@/components/editor/color-picker.vue";
import TogglePanel from "@/components/control-sidebar/toggle-panel.vue";
import Tooltip from "@/components/tooltip.vue";
export default {
  name: "DisconnectionChartForm",
  components: {
    ColorPicker,
    Tooltip,
    TogglePanel
  },
  props: {
    value: {
      type: Object,
      required: true,
      default: () => null
    }
  },
  data() {
    return {
      formData: {
        showDisconnection: false,
        disconnectionColor: "#FFFFFF",
        disconnectedDataLine: "trend",
        disconnectionThreshold:
          this?.$http?.options?.config?.disconnection_threshold_config
            ?.default ?? 30
      }
    };
  },
  computed: {
    options() {
      return (
        this?.$http?.options?.config?.disconnection_threshold_config
          ?.options ?? [
          {value: 30, label: "> 30s"},
          {value: 60, label: "> 1m"},
          {value: 300, label: "> 5m"},
          {value: 600, label: "> 10m"},
          {value: 900, label: "> 15m"},
          {value: 1800, label: "> 30m"},
          {value: 3600, label: "> 1"},
          {value: 7200, label: "> 2"},
          {value: 14400, label: "> 4"},
          {value: 14400, label: "> 4"},
          {value: 28800, label: "> 8"},
          {value: 43200, label: "> 12"},
          {value: 86400, label: "> 24"}
        ]
      );
    }
  },
  watch: {
    formData: {
      handler(n) {
        this.$emit("input", n);
      },
      deep: true
    }
  },
  mounted() {
    for (var p in this.formData) {
      if (this.value[p] !== undefined) {
        this.formData[p] = this.value[p];
      }
    }
  }
};
</script>

<style scoped>
div.inner-box {
  padding: 10px;
  border: 1px solid lightgray;
  border-radius: 5px;
  margin-top: -5px;
}
</style>
