import { render, staticRenderFns } from "./chart-axis-x-form.vue?vue&type=template&id=4e081276&scoped=true&"
import script from "./chart-axis-x-form.vue?vue&type=script&lang=js&"
export * from "./chart-axis-x-form.vue?vue&type=script&lang=js&"
import style0 from "./chart-axis-x-form.vue?vue&type=style&index=0&id=4e081276&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e081276",
  null
  
)

export default component.exports